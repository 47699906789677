.footer
    padding-top: 55px
    padding-bottom: 58px
    background: url('../img/bg/pattern_bg.png') center

.footer-nav
    line-height: 1.3
    padding-top: 14px
    padding-bottom: 5px
    border-left: 2px solid #D9D9D9

.footer-nav__item
    padding-left: 28px
    padding-right: 28px
    margin-bottom: 10px

.footer-nav__link
    font-size: 14px
    font-weight: 700
    color: $gray-dark
    letter-spacing: .05em
    text-transform: uppercase
    &:hover,
    &:focus-visible
        color: $blue
