
.modal-open
    overflow: hidden

    & .modal
        overflow-x: hidden
        overflow-y: auto

.modal
    position: fixed
    top: 0
    left: 0
    z-index: 1050
    display: none
    width: 100%
    height: 100%
    overflow: hidden
    outline: 0

    &-dialog
        position: relative
        width: auto
        margin: 0.5rem
        pointer-events: none

    &.fade .modal-dialog
        transition: -webkit-transform 0.3s ease-out
        transition: transform 0.3s ease-out
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
        -webkit-transform: translate(0, -50px)
        transform: translate(0, -50px)

@media (prefers-reduced-motion: reduce)
.modal.fade .modal-dialog
    transition: none

.modal.show .modal-dialog
    -webkit-transform: none
    transform: none

.modal.modal-static .modal-dialog
    -webkit-transform: scale(1.02)
    transform: scale(1.02)

.modal-dialog-scrollable
    display: -ms-flexbox
    display: flex
    max-height: calc(100% - 1rem)

    & .modal-content
        max-height: calc(100vh - 1rem)
        overflow: hidden

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer
    -ms-flex-negative: 0
    flex-shrink: 0

.modal-dialog-scrollable .modal-body
    overflow-y: auto

.modal-dialog-centered
    display: -ms-flexbox
    display: flex
    -ms-flex-align: center
    align-items: center
    min-height: calc(100% - 1rem)

    &::before
        display: block
        height: calc(100vh - 1rem)
        height: -webkit-min-content
        height: -moz-min-content
        height: min-content
        content: ""

    &.modal-dialog-scrollable
        -ms-flex-direction: column
        flex-direction: column
        -ms-flex-pack: center
        justify-content: center
        height: 100%

    &.modal-dialog-scrollable .modal-content
        max-height: none

    &.modal-dialog-scrollable::before
        content: none

.modal-content
    position: relative
    display: -ms-flexbox
    display: flex
    -ms-flex-direction: column
    flex-direction: column
    width: 100%
    pointer-events: auto
    background-clip: padding-box
    border-radius: 0.3rem
    outline: 0

.modal-backdrop
    position: fixed
    top: 0
    left: 0
    z-index: 1040
    width: 100vw
    height: 100vh
    min-width: 360px
    min-height: 100%
    background: url('../img/bg/pattern_blue_bg.jpg')

    &.fade
        opacity: 0

    &.show
        opacity: 0.9

.modal-header
    display: -ms-flexbox
    display: flex
    -ms-flex-align: start
    align-items: flex-start
    -ms-flex-pack: justify
    justify-content: space-between
    padding: 1rem 1rem
    border-bottom: 1px solid #dee2e6
    border-top-left-radius: calc(0.3rem - 1px)
    border-top-right-radius: calc(0.3rem - 1px)

    & .close
        padding: 1rem 1rem
        margin: -1rem -1rem -1rem auto

.modal-title
    margin-bottom: 0
    line-height: 1.5

.modal-body
    position: relative
    -ms-flex: 1 1 auto
    flex: 1 1 auto
    padding: 1rem
    .close
        color: #fff

.modal-footer
    display: -ms-flexbox
    display: flex
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    -ms-flex-align: center
    align-items: center
    -ms-flex-pack: end
    justify-content: flex-end
    padding: 0.75rem
    border-top: 1px solid #dee2e6
    border-bottom-right-radius: calc(0.3rem - 1px)
    border-bottom-left-radius: calc(0.3rem - 1px)

    & > *
        margin: 0.25rem

.modal-scrollbar-measure
    position: absolute
    top: -9999px
    width: 50px
    height: 50px
    overflow: scroll

@media (min-width: 576px)
.modal-dialog
    max-width: 500px
    margin: 1.75rem auto

    &-scrollable
        max-height: calc(100% - 3.5rem)

    &-scrollable .modal-content
        max-height: calc(100vh - 3.5rem)

    &-centered
        min-height: calc(100% - 3.5rem)

    &-centered::before
        height: calc(100vh - 3.5rem)
        height: -webkit-min-content
        height: -moz-min-content
        height: min-content

.modal-sm
    max-width: 300px

@media (min-width: 992px)
.modal-lg,
.modal-xl
    max-width: 800px

@media (min-width: 1200px)
.modal-xl
    max-width: 1140px
