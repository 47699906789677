.contact
	margin-left: auto
	margin-right: auto
	position: relative
	max-width: 1918px
	background: url('../img/bg/contact_bg.jpg') no-repeat left top

.contact-hr
	height: 2px
	margin-left: -40px
	margin-right: -40px
	margin-bottom: 27px
	background-color: #0E3A88

.from-contact
	height: 100%
	width: 666px
	padding-top: 60px
	padding-bottom: 46px
	transform: translateX(120px)
	background: url('../img/bg/form_contact_bg.jpg') no-repeat center / cover
	.form-title
		margin-bottom: 20px
	.container
		height: 100%

.form-row
	width: 445px
	// height: 600px
	min-height: 100%

.contact-col
	min-height: 100%

.contact-table
	line-height: 1.5
	tr
		td
			&:first-child
				padding-right: 80px

.contact-front-col
	background: url('../img/bg/contact_front_bg.jpg') no-repeat

.contact-front
	.from-contact
		transform: none

.contact-col
	margin-right: -73px
	background: url(../img/bg/contact_bg.jpg) no-repeat center right/cover

@media (max-width: 1919px)
	.from-contact
		transform: translateX(40px)

@include media-breakpoint-down(xl)

	.contact-front
		.contact-col
			margin-right: 0
			background: none
	.form-row
		width: 360px
	.from-contact
		width: 450px
	.contact-col
		margin-right: -73px
		background: url(../img/bg/contact_bg.jpg) no-repeat center right /cover

@include media-breakpoint-down(lg)
	.contact-hr
		margin-left: -20px
		margin-right: -20px
	.contact-col
		background: url(../img/bg/contact_bg.jpg) no-repeat center right -130px /cover

@include media-breakpoint-down(md)
	.contact-front-col
		background: url(../img/bg/contact_bg.jpg) no-repeat center right -10px /cover
	.contact-col
		margin-right: -20px
		background: url(../img/bg/contact_bg.jpg) no-repeat center right -10px /cover
	.from-contact
		transform: none
		width: calc(100% + 40px)
		margin-left: -20px
		margin-right: -20px
	.contact
		background: none
	.form-row
		width: 100%
		height: auto
	.contact-hr
		margin-left: -10px
		margin-right: -10px
	// .contact-col
	// 	background: url('../img/bg/contact_bg.jpg') no-repeat center / cover

	.contact-table
		display: block
		tr
			display: block
			margin-bottom: 10px
			td
				display: block
				&:first-child
					font-weight: 700
					margin-bottom: 5px
					padding-right: 0

@include media-breakpoint-down(sm)
	.contact-front-col
		background: url(../img/bg/contact_bg.jpg) no-repeat top right -166px/cover
		background-size: 962px
	.contact-table
		font-size: 13px
		max-width: 100%
		width: 100%
	.contact-col
		background: url(../img/bg/contact_bg.jpg) no-repeat center right -100px /cover

@include media-breakpoint-down(xs)
	.contact-col
		background: url(../img/bg/contact_bg.jpg) no-repeat center right -200px /cover
