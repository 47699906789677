body
	line-height: 1.6
	min-width: 360px
	font-size: 16px
	color: $black
	font-family: Lato, Arial
	text-rendering: optimizeLegibility !important
	-webkit-font-smoothing: antialiased !important
	-moz-osx-font-smoothing: grayscale !important

svg
	fill: currentColor

.img-fluid
	max-width: 100%
	height: auto

.text-truncate
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis

a,
button
	cursor: pointer

.o-h
	overflow: hidden

*:focus
	outline: 0 !important

a,
button
	-webkit-appearance: none

ul:not([class])
	margin-bottom: 20px
	li
		padding-left: 24px
		position: relative
		&:before
			width: 6px
			height: 6px
			left: 9px
			top: 10px
			content: ''
			background: #000
			position: absolute
			border-radius: 50%

ol:not([class])
	margin-bottom: 20px
	counter-reset: count
	li
		padding-left: 24px
		position: relative
		counter-increment: count
		&:before
			left: 0
			width: 20px
			text-align: right
			content: counter(count, decimal) '.'
			position: absolute
			border-radius: 50%

.nowrap
	white-space: nowrap

.content-imgs
	flex: 0 0 100%
	max-width: 40%

	img:not(:first-child)
		display: block
		margin-top: 60px

h1, h2, h3, h4
	font-weight: 900
	line-height: 1.3

h1
	font-size: 24px
	margin-top: 54px
	margin-bottom: 47px
	letter-spacing: .04em
	text-transform: uppercase

h2
	font-size: 20px
	margin-top: 50px
	margin-bottom: 23px
	letter-spacing: .03em

h3
	font-size: 18px
	margin-top: 18px
	font-weight: 900
	margin-bottom: 20px
	letter-spacing: .03em

h4
	margin-top: 18px
	margin-bottom: 20px
	letter-spacing: .03em

h5
	margin-top: 15px
	margin-bottom: 20px
	letter-spacing: .03em

p
	&:not(:last-child)
		margin-bottom: 22px

.content
	line-height: 1.5

	// img
	// 	&:not(:first-child)
	// 		margin-top: 35px
	// 		margin-bottom: 40px

	p
		a
			text-decoration: underline
		&:hover
			text-decoration: none

blockquote
	font-size: 20px
	font-weight: 700
	font-style: italic
	margin-bottom: 40px
	padding: 15px 10px 10px 87px
	margin-top: 40px
	background: url('../img/bg/blockquote.png') no-repeat
	background-size: 54px

.container
	padding-left: 40px
	padding-right: 40px
	margin-right: auto
	margin-left: auto

.link-white
	color: #fff
	&:hover,
	&:focus-visible
		text-decoration: underline

.title
	line-height: 1.3
	font-size: 48px
	font-weight: 900
	padding-left: 55px
	position: relative
	&::before
		content: ""
		left: 0
		top: 13px
		width: 34px
		height: 34px
		position: absolute
		background: url('../img/bg/yellow_square.svg')

.lt-5
	letter-spacing: .05em

.lt-3
	letter-spacing: .03em

.bd-x-0
	border-left: 0
	border-right: 0

.subtitle
	line-height: 1.3
	font-size: 24px
	font-weight: 900
	padding-left: 45px
	position: relative
	&::before
		content: ""
		left: 0
		top: 3px
		width: 24px
		height: 24px
		position: absolute
		background: url('../img/bg/yellow_square.svg')

.link
	color: $blue
	align-items: center
	display: inline-flex
	letter-spacing: .03em
	text-decoration: underline
	&:hover
		text-decoration: none

.bd-l-0
	border-left: 0

.article
	letter-spacing: .03em
	background: url('../img/bg/pattern_bg.png')

.article-text
	line-height: 1.5

.article-name
	font-size: 24px
	font-weight: 700
	margin-bottom: 13px

.article-img
	width: 375px
	height: 100%
	display: block
	background-size: cover
	background-position: center
	background-repeat: no-repeat

.article-body
	padding: 59px 64px 54px 72px

.article-btn
	padding: 15px 53px

.article-date
	font-size: 12px
	color: #959595
	margin-bottom: 10px

.wrapper
	overflow: hidden

.page-error
	background: url('../img/bg/error_bg.jpg') no-repeat center / cover

.error-left
	width: 350px

.error-text a
	color: #0E3A88
	text-decoration: underline
	&:hover
		text-decoration: none

@include media-breakpoint-up(xxl)
	.container
		max-width: 1758px

@include media-breakpoint-down(xl)
	.article-body
		padding: 40px 50px
	.content-imgs img:not(:first-child)
		margin-top: 35px
	.title
		font-size: 42px
	.title::before
		top: 13px
		width: 30px
		height: 30px

@include media-breakpoint-down(lg)
	.error-left
		width: 240px
	.article-body
		padding: 30px 50px
	.article-img
		width: 250px
	.title
		font-size: 36px
		padding-left: 45px
	.title::before
		top: 11px
		width: 25px
		height: 25px

	.subtitle
		font-size: 20px
		padding-left: 35px
		&::before
			top: 3px
			width: 20px
			height: 20px

@include media-breakpoint-down(md)
	.error-left
		width: 100%
	a.article-img
		display: none
	.article-body
		padding: 25px 30px 30px
	blockquote
		font-size: 18px
		background-size: 40px
		padding-left: 50px
	h1
		margin-top: 50px
		margin-bottom: 35px
	h2
		margin-top: 30px
		margin-bottom: 20px
	.content-imgs
		max-width: none
	.content-imgs img:not(:first-child)
		margin-top: 20px

@include media-breakpoint-down(md)
	.container
		padding-left: 20px !important
		padding-right: 20px !important

@include media-breakpoint-down(sm)
	h1
		font-size: 22px
		margin-top: 40px
		margin-bottom: 25px
	blockquote
		font-size: 16px
		background-size: 30px
		padding-left: 45px
		margin-top: 30px
		padding-top: 0
		margin-bottom: 30px
	.title
		font-size: 30px
		padding-left: 35px
		&::before
			top: 10px
			width: 20px
			height: 20px

	.subtitle
		font-size: 18px
		padding-left: 30px
		&::before
			top: 2px
			width: 18px
			height: 18px

@include media-breakpoint-down(xs)
	.article-name
		font-size: 20px
	.article-body
		padding-left: 20px
		padding-right: 20px
	.article-btn
		padding: 10px 40px
	.title
		font-size: 28px
		padding-left: 30px
		&::before
			top: 8px
			width: 18px
			height: 18px
