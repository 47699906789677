.filter
    width: 375px
    padding: 37px 33px 33px
    background: url('../img/bg/pattern_blue_bg.jpg')

.filter-item-inner
    padding-left: 20px
    padding-top: 7px

.filter-item
    margin-bottom: 18px

.filter-subitem
    margin-bottom: 10px

.filter-item__link
    font-weight: 700
    font-size: 18px
    position: relative
    &.active
        color: $yellow
        padding-left: 20px
        &::before
            top: 10px
            content: ''
            width: 10px
            height: 10px
            left: 0
            position: absolute
            background: url('../img/bg/yellow_square.svg')
    &:hover,
    &:focus-visible
        color: $yellow

.filter-item__sublink
    &:hover,
    &:focus-visible
        color: $yellow

.bottom-nav
    display: flex
    padding-left: 20px
    padding-right: 20px
    align-items: center

.bottom-nav-link
    width: 20px
    height: 20px
    display: flex
    font-size: 18px
    font-weight: 700
    align-items: center
    justify-content: center
    line-height: 2
    color: $blue
    margin: 0 2px
    &:hover,
    &:focus-visible
        color: $yellow
    &.active
        background: $yellow

@include media-breakpoint-down(xl)
    .filter
        width: 300px
        padding: 30px 20px 35px

    .checkbox_filter .checkbox__text
        font-size: 14px
        padding-left: 17px

    .checkbox_filter .checkbox__input:checked ~ .checkbox__text::before
        top: 6px
        width: 8px
        height: 8px

@include media-breakpoint-down(lg)
    .filter
        position: fixed
        left: 0
        top: 0
        z-index: 12
        bottom: 0
        transition: all .3s
        overflow-y: auto

    body:not(.filter-open)
        .filter
            transform: translateX(-100%)
    // body.filter-open
        // overflow: hidden
        // &::before
        //     left: 0
        //     right: 0
        //     bottom: 0
        //     top: 0
        //     z-index: 12
        //     content: ''
        //     position: fixed
        //     background: rgba(0,0,0,.5)

@include media-breakpoint-down(sm)
    .filter-item
        margin-bottom: 18px
    .bottom-nav
        padding-left: 14px
        padding-right: 14px
    .bottom-nav-link
        width: 18px
        height: 18px
        font-size: 16px
