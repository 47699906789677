// -------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints)
	+media-breakpoint-up($breakpoint)
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints)

		@each $size in $font-size
			.fz#{$infix}-#{$size}
				font-size: #{$size}px

// -------------------------------------------------------------------------------------------

@each $color, $value in $color-custom
	.c-#{"" + $color}
		color: $value

	a.c-#{"" + $color}:hover
		color: $black
		text-decoration: none

.c-vk
	color: #5980B7
.c-fb
	color: #3F579D
.c-tt
	color: #55ACEE
.c-yt
	color: #D42428

// -------------------------------------------------------------------------------------------

@each $breakpoint in map-keys($grid-breakpoints)
	+media-breakpoint-up($breakpoint)
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints)

		.ta#{$infix}-l
			text-align: left

		.ta#{$infix}-r
			text-align: right

		.ta#{$infix}-c
			text-align: center

// -------------------------------------------------------------------------------------------

.fw-100
	font-weight: 100
.fw-200
	font-weight: 200
.fw-300
	font-weight: 300
.fw-400
	font-weight: 400
.fw-500
	font-weight: 500
.fw-600
	font-weight: 600
.fw-700
	font-weight: 700
.fw-800
	font-weight: 800
.fw-900
	font-weight: 900

// -------------------------------------------------------------------------------------------

.fs-i
	font-style: italic

// -------------------------------------------------------------------------------------------

.lh-100
	line-height: 1
.lh-110
	line-height: 1.1
.lh-120
	line-height: 1.2
.lh-130
	line-height: 1.3
.lh-140
	line-height: 1.4
.lh-150
	line-height: 1.5
.lh-160
	line-height: 1.6
.lh-170
	line-height: 1.7
.lh-180
	line-height: 1.8
.lh-190
	line-height: 1.9
.lh-200
	line-height: 2

// -------------------------------------------------------------------------------------------

.td-lt
	text-decoration: line-through

.td-n
	text-decoration: none

.td-u
	text-decoration: underline

a.td-u:hover
	text-decoration: none

// -------------------------------------------------------------------------------------------

.tt-c
	text-transform: capitalize

.tt-l
	text-transform: lowercase

.tt-n
	text-transform: none

.tt-u
	text-transform: uppercase

// -------------------------------------------------------------------------------------------

.ws-nw
	white-space: nowrap

// -------------------------------------------------------------------------------------------

.ls-10
	letter-spacing: .1px
.ls-20
	letter-spacing: .2px
.ls-30
	letter-spacing: .3px
.ls-40
	letter-spacing: .4px
.ls-50
	letter-spacing: .5px
.ls-60
	letter-spacing: .6px
.ls-70
	letter-spacing: .7px
.ls-90
	letter-spacing: .9px
.ls-80
	letter-spacing: .8px
.ls-100
	letter-spacing: 1px
.ls-110
	letter-spacing: 1.1px
.ls-120
	letter-spacing: 1.2px
.ls-160
	letter-spacing: 1.6px
.ls-240
	letter-spacing: 2.4px
.ls-400
	letter-spacing: 4px
