.front
	padding-top: 236px
	background: url('../img/bg/header_bg.jpg') no-repeat center / cover

.front-left
	width: 486px

.front-swiper__name
	top: 25px
	right: 72px
	width: 200px
	color: #fff
	font-weight: 700
	line-height: 1.2
	text-align: left
	letter-spacing: .03em
	position: absolute

.front-nav
	right: 0
	top: 80px
	z-index: 1
	color: #fff
	text-align: center
	position: absolute

.front-nav__next,
.front-nav__prev
	color: #fff
	&:focus-visible
		color: $yellow

.front-swiper
	.swiper-slide:not(.swiper-slide-active)
		opacity: 0 !important

.front-swiper-scrollbar
	overflow: hidden
	width: 130px
	height: 3px
	background: #B6B6B6
	transform: translate(-60px, 77px) rotate(90deg)

.front-swiper-scrollbar-wrapper
	height: 156px
	width: 3px

.about-left
	height: 100%
	background: url('../img/bg/about_bg.jpg') no-repeat center / cover

.about-right
	padding: 19% 16% 16% 15%
	background: url('../img/bg/pattern_bg.png')

.about-text
	line-height: 1.5
	padding-top: 50px
	letter-spacing: .03em

.swiper-pagination_current
	font-size: 0
	.swiper-pagination-current
		font-size: 16px
		font-weight: 700

.swiper-pagination_total
	font-size: 0
	.swiper-pagination-total
		font-size: 16px
		font-weight: 700

.about-swiper-scrollbar
	height: 3px
	width: 154px
	overflow: hidden
	background: #000

.swiper-scrollbar-drag
	position: relative
	background: #FFC700
	&:after
		top: 0
		bottom: 0
		content: ''
		right: 100%
		width: 1000px
		position: absolute
		background: #FFC700

.front-text
	color: #0E0E0E

@include media-breakpoint-up(xl)
	.front-nav__next,
	.front-nav__prev
		&:hover
			color: $yellow

@include media-breakpoint-down(xl)
	.front-left
		width: 370px
	.front
		padding-top: 170px
	.about-right
		padding: 10%

@include media-breakpoint-down(lg)
	.front-left
		width: 100%
	.front
		padding-top: 130px

@include media-breakpoint-down(md)
	.about-right
		padding: 60px 40px
	.front-swiper__name
		position: static
		width: 100%
		margin-bottom: 15px
		br
			display: none

	.front-nav__next,
	.front-nav__prev
		z-index: 1
		top: 50%
		position: absolute
		transform: translateY(-50%)

	.front-nav__next
		right: 10px

	.front-nav__prev
		left: 10px

@include media-breakpoint-down(xs)
	.about-right
		padding: 50px 20px
	.front
		padding-top: 100px
	.front-swiper__name
		font-size: 15px
	.about-text
		padding-top: 25px
