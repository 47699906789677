.hamburger
    padding: 0
    display: block
    cursor: pointer
    &:focus-visible
        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before
            background-color: #fff

.hamburger-box
    width: 100%
    height: 100%
    display: block
    position: relative

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before
    width: 24px
    height: 2px
    background-color: #0E3A88
    border-radius: 2px
    position: absolute
    transition-duration: .15s
    transition-timing-function: ease

.hamburger-inner
    left: 50%
    top: 20px
    margin-left: -12px
    transition-property: transform

.hamburger-inner:after,
.hamburger-inner:before
    content: ""
    display: block
    transition-property: none

.hamburger-inner:after
    top: -8px

.hamburger-inner:before
    bottom: -8px

body.catalog-open .hamburger-inner
    transform: rotate(45deg)

body.catalog-open .hamburger-inner:after
    opacity: 0

body.catalog-open .hamburger-inner:before
    bottom: 0
    transform: rotate(-90deg)

@include media-breakpoint-up(xxl)
    .hamburger
        display: none

@include media-breakpoint-up(lg)
    .hamburger
        &:hover
            .hamburger-inner,
            .hamburger-inner:after,
            .hamburger-inner:before
                background-color: #fff
    body.catalog-open
        .page-header_front
            .hamburger
                &:hover
                    .hamburger-inner,
                    .hamburger-inner:after,
                    .hamburger-inner:before
                        background-color: $blue

@include media-breakpoint-down(xs)
    .hamburger-inner
        top: 15px
