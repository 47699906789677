.page-header
	min-width: 360px
	background: url('../img/bg/pattern_bg.png') center

.page-header_front
	left: 0
	right: 0
	top: 0
	background: none
	position: absolute
	.container
		background: none !important

.header-menu__link
	color: #000
	&:hover,
	&:focus-visible
		color: $blue

body:not(.catalog-open)
	.page-header_front
		min-width: 360px
		.container
			background: none
		.logo_img
			color: #fff
		.header-menu__link
			color: #fff
			&:hover,
			&:focus-visible
				color: $yellow
		.header-phone
			color: #fff
			&:hover,
			&:focus-visible
				color: $yellow
			.icon
				color: $yellow
		.header-search-btn
			color: $yellow
			border-color: $yellow
			&:hover,
			&:focus-visible
				color: $blue
				background: $yellow
		.hamburger
			color: $yellow
			border-color: $yellow
			.hamburger-inner,
			.hamburger-inner:after,
			.hamburger-inner:before
				background: $yellow
			&:hover,
			&:focus-visible
				background: $yellow
				.hamburger-inner,
				.hamburger-inner:after,
				.hamburger-inner:before
					background: $blue
		.catalog-menu__inner
			border-color: $yellow
		.catalog-menu__item
			color: #fff
			&:hover,
			&:focus-visible
				color: $yellow

.logo_img
	transform: translateY(2px)

@include media-breakpoint-down(xl)
	.page-header .container
		background: url('../img/bg/pattern_bg.png') center
	body:not(.menu-open)
		.header-menu
			display: none

// @include media-breakpoint-down(lg)
// 	.page-header
// 		position: sticky
