@font-face
    font-family: 'Lato'
    src: local('Lato'), url(../fonts/latoregular.woff2) format('woff2')
    font-weight: 400
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Lato'
    src: local('Lato'), url(../fonts/latoitalic.woff2) format('woff2')
    font-weight: 400
    font-style: italic
    font-display: swap

@font-face
    font-family: 'Lato'
    src: local('Lato'), url(../fonts/latomedium.woff2) format('woff2')
    font-weight: 500
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Lato'
    src: local('Lato'), url(../fonts/latosemibold.woff2) format('woff2')
    font-weight: 600
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Lato'
    src: local('Lato'), url(../fonts/latobold.woff2) format('woff2')
    font-weight: 700
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Lato'
    src: local('Lato'), url(../fonts/latoblack.woff2) format('woff2')
    font-weight: 900
    font-style: normal
    font-display: swap
