.catalog-menu__inner
    border-top: 2px solid #0E3A88

.catalog-menu__row
    display: flex
    padding-top: 18px
    margin-left: -10px
    margin-right: -10px
    padding-bottom: 20px
    justify-content: space-between

.catalog-menu__item
    font-size: 14px
    font-weight: 700
    padding-left: 10px
    padding-right: 10px
    text-transform: uppercase
    &:hover,
    &:focus-visible
        color: $blue

@include media-breakpoint-down(xl)
    body:not(.catalog-open) .catalog-menu
        transform: translateX(100%)
    .catalog-menu
        top: 0
        left: 0
        right: 0
        bottom: 0
        position: fixed
        overflow-y: auto
        padding-top: 100px
        transition: all .5s
        background: url(../img/bg/pattern_bg.png)
    .catalog-menu__inner
        height: 100%
        border-top: 0
    .catalog-menu__row
        min-height: 100%
        padding-top: 20px
        padding-bottom: 60px
        align-items: center
        justify-content: center
        flex-direction: column
    .catalog-menu__item
        margin-bottom: 30px
    .catalog-menu__container,
    .catalog-menu__container
        height: 100%
    body.catalog-open
        overflow: hidden

@include media-breakpoint-down(sm)
    .catalog-menu__item
        margin-bottom: 25px
    .catalog-menu
        padding-top: 70px
    .catalog-menu__item
        margin-bottom: 15px
    .catalog-menu__row
        padding-bottom: 30px
