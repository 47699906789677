
.relative
    position: relative

.z-11
    z-index: 11

.z-1
    z-index: 1

.block
    display: block
.flex
    display: -webkit-box
    display: -ms-flexbox
    display: flex
.inline-flex
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex
.hidden
    display: none
.h-36
    height: 36px
.h-28
    height: 28px
.h-20
    height: 20px
.h-12
    height: 12px
.w-150
    width: 150px
.w-28
    width: 28px
.w-36
    width: 36px
.w-20
    width: 20px
.w-12
    width: 12px

.flex-initial
    -webkit-box-flex: 0
    -ms-flex: 0 1 auto
    flex: 0 1 auto
.flex-1
    -webkit-box-flex: 1
    -ms-flex: 1 1 0%
    flex: 1 1 0%
.items-start
    -webkit-box-align: start
    -ms-flex-align: start
    align-items: flex-start
.items-center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
.justify-center
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
.justify-between
    -webkit-box-pack: justify
    -ms-flex-pack: justify
    justify-content: space-between
.bg-gray
    background-color: rgb(249 249 249 / var(--tw-bg-opacity))
.text-12
    font-size: 12px
.font-600
    font-weight: 600
.font-500
    font-weight: 500
.uppercase
    text-transform: uppercase
.leading-100
    line-height: 1
.c-black
    color: rgb(0 0 0 / var(--tw-text-opacity))
.text-gray-light
    color: rgb(189 189 189 / var(--tw-text-opacity))
.hover_text-black:hover
    color: rgb(0 0 0 / var(--tw-text-opacity))

@media (max-width: 1365.08px)
    .xl-max_hidden
        display: none

    .xl-max_flex-auto
        -webkit-box-flex: 1
        -ms-flex: 1 1 auto
        flex: 1 1 auto

@media (max-width: 767.08px)
    .md-max_hidden
        display: none

@media (min-width: 576.08px)
    .sm_ml-20
        margin-left: 20px

    .sm_h-44
        height: 44px

    .sm_h-25
        height: 25px

    .sm_w-190
        width: 190px

    .sm_w-44
        width: 44px

    .sm_w-25
        width: 25px

@media (min-width: 1024px)
    .lg_order-2
        -webkit-box-ordinal-group: 3
        -ms-flex-order: 2
        order: 2

    .lg_order-1
        -webkit-box-ordinal-group: 2
        -ms-flex-order: 1
        order: 1

    .lg_flex
        display: -webkit-box
        display: -ms-flexbox
        display: flex

    .lg_pl-35
        padding-left: 35px

@media (min-width: 1366px)
    .xl_h-50
        height: 50px

    .xl_w-210
        width: 210px

    .xl_py-25
        padding-top: 25px
        padding-bottom: 25px

    .xl_pl-50
        padding-left: 50px

@media (min-width: 1700px)
    .xxl_mx-20
        margin-left: 20px
        margin-right: 20px

    .xxl_ml-45
        margin-left: 45px

    .xxl_py-35
        padding-top: 35px
        padding-bottom: 35px
