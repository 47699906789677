.btn
	cursor: pointer
	font-weight: 700
	border-width: 2px
	border-style: solid
	display: inline-flex
	align-items: center
	justify-content: center
	border-color: transparent
	&.disabled,
	&:disabled
		pointer-events: none

.btn-blue
	color: #0E3A88
	border-color: #0E3A88
	&:focus-visible
		color: #fff
		background: #0E3A88

.btn-yellow
	color: #FFC700
	border-color: #FFC700
	&:focus-visible
		color: #0E3A88
		background: #FFC700

@include media-breakpoint-up(lg)
	.btn-blue
		&:hover
			color: #fff
			background: #0E3A88

	.btn-yellow
		&:hover
			color: #0E3A88
			background: #FFC700
