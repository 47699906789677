@each $breakpoint in map-keys($grid-breakpoints)
	@include media-breakpoint-up($breakpoint)
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints)

		@each $prop, $abbrev in (margin: m, padding: p)
			@each $size in $spacer-custom
				.#{$abbrev}#{$infix}-#{$size} 
					#{$prop}: #{$size}px
				
				.#{$abbrev}t#{$infix}-#{$size},
				.#{$abbrev}y#{$infix}-#{$size}
					#{$prop}-top: #{$size}px
				
				.#{$abbrev}r#{$infix}-#{$size},
				.#{$abbrev}x#{$infix}-#{$size}
					#{$prop}-right: #{$size}px
				
				.#{$abbrev}b#{$infix}-#{$size},
				.#{$abbrev}y#{$infix}-#{$size}
					#{$prop}-bottom: #{$size}px
				
				.#{$abbrev}l#{$infix}-#{$size},
				.#{$abbrev}x#{$infix}-#{$size}
					#{$prop}-left: #{$size}px


		.m#{$infix}-a
			margin: auto
		
		.mt#{$infix}-a,
		.my#{$infix}-a
			margin-top: auto
		
		.mr#{$infix}-a,
		.mx#{$infix}-a
			margin-right: auto
		
		.mb#{$infix}-a,
		.my#{$infix}-a
			margin-bottom: auto
		
		.ml#{$infix}-a,
		.mx#{$infix}-a
			margin-left: auto