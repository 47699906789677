.va-b
    vertical-align: bottom
.va-bs
    vertical-align: baseline
.va-m
    vertical-align: middle
.va-t
    vertical-align: top
.va-s
    vertical-align: sub
