.form
    padding-top: 42px
    padding-bottom: 50px
    background: url('../img/bg/form_bg.jpg') no-repeat center / cover

.form-title
    color: #fff
    font-size: 36px
    font-weight: 900
    text-align: center
    margin-bottom: 38px
    letter-spacing: .03em

.form-subtitle
    color: #fff
    text-align: center
    font-weight: 700
    margin-bottom: 23px

.form-btn
    width: 198px
    min-height: 60px

.label
    font-weight: 500
    font-size: 14px
    color: #fff
    line-height: 1.5
    padding-left: 23px
    margin-bottom: 9px

.input
    width: 100%
    height: 60px
    color: #fff
    padding-left: 24px
    padding-right: 50px
    border: 2px solid #fff
    &:placeholder-shown
        color: #fff
    &.input-error
        border-color: #FF2626

.error
    color: #FF2626
    font-size: 14px
    font-weight: 500
    padding-top: 5px
    line-height: 1.2

.input_textarea
    height: 120px
    padding-top: 15px

.input-icon
    top: 50%
    right: 23px
    color: $yellow
    position: absolute
    transform: translateY(-50%)

.input-icon_textarea
    top: 21px
    transform: none

.input-wrap
    position: relative

.hashtag
    display: inline-flex
    margin-bottom: 0
    position: relative
    cursor: pointer
    user-select: none

    &__text
        display: flex
        font-size: 14px
        min-height: 35px
        font-weight: 700
        align-items: center
        padding-left: 20px
        padding-right: 20px
        background: #F9F9F9

    &__input
        display: none

        &:checked ~ .hashtag__text
            color: #fff
            background: $blue

    &:hover
        .hashtag__text
            color: #fff
            background: $blue
.checkbox
    display: inline-flex
    margin-bottom: 0
    position: relative
    cursor: pointer
    user-select: none

    &__text
        font-size: 12px
        padding-left: 20px
        display: block
        position: relative
        min-height: 24px
        color: #fff
        text-align: left
        &::before
            content: ''
            width: 14px
            height: 15px
            position: absolute
            top: 1px
            left: 1px
            z-index: 1

        &::after
            content: ''
            width: 10px
            height: 10px
            position: absolute
            top: 5px
            left: 0
            border: 2px solid #fff

    &__input
        display: none

        &:checked ~ .checkbox__text:before
            background: url('../img/icons/icon_checkbox.svg') no-repeat center / 14px auto

    &_sort
        .checkbox__text
            padding-left: 0
            font-size: 18px
            font-weight: 700
            color: #8B8B8B
            &:after,
            &:before
                content: none
        .checkbox__input
            &:checked ~ .checkbox__text
                color: $blue

    &_filter
        &:hover
            .checkbox__text
                color: $yellow
        .checkbox__text
            font-size: 18px
            font-weight: 700
            color: #fff
            padding-left: 0
            // text-transform: uppercase
            &:after,
            &:before
                content: none
        .checkbox__input
            &:checked ~ .checkbox__text
                color: $yellow
                padding-left: 20px
                &::before
                    top: 8px
                    content: ''
                    width: 10px
                    height: 10px
                    background: url('../img/bg/yellow_square.svg')

    &_subfilter
        &:not(:last-child)
            margin-bottom: 10px
        &:hover
            .checkbox__text
                color: $yellow
        .checkbox__text
            font-size: 16px
            color: #fff
            padding-left: 0
            &:after,
            &:before
                content: none
        .checkbox__input
            &:checked ~ .checkbox__text
                color: $yellow

.form-title_mb-sm
    margin-bottom: 15px !important

@media screen and (-webkit-min-device-pixel-ratio: 0)
    .input
        font-size: 16px !important

@include media-breakpoint-down(lg)
    .form-btn
        width: 160px

@include media-breakpoint-down(md)
    .form-title
        margin-bottom: 20px
    .form-btn
        width: 100%
    .checkbox_sort
        .checkbox__text
            font-size: 16px

@include media-breakpoint-down(sm)
    .form-title
        font-size: 28px
