.card
    height: 100%
    display: flex
    letter-spacing: .03em
    flex-direction: column
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1)
    background: url('../img/bg/pattern_bg.png')

.card-link
    display: block
    position: relative
    text-align: center
    background: #fff
    border-bottom: 4px solid $blue
    img
        display: block
        mix-blend-mode: darken

.card-img-link
    display: block
    padding-bottom: 59%
    background-position: center
    background-repeat: no-repeat
    background-size: cover

.card-body__text_article
    padding-bottom: 0 !important

.card-swiper
    .swiper-slide
        height: auto

.card-body
    flex-grow: 1
    display: flex
    flex-basis: 0
    max-width: 100%
    line-height: 1.3
    align-items: start
    flex-direction: column
    padding: 30px 60px 50px

.card-body__name
    font-size: 20px
    font-weight: 700

.card-body__text
    line-height: 1.5
    padding-top: 24px
    padding-bottom: 37px
    flex-basis: 0
    flex-grow: 1
    max-width: 100%

.card_filter
    .card-body
        justify-content: center
        padding: 24px 32px 35px
    .card-body__name
        font-size: 17px
        line-height: 1.5
    .card-body__text
        padding-bottom: 26px
        padding-top: 21px
    .card-btn
        padding-top: 12px
        padding-bottom: 12px

.card-labels
    top: 36px
    z-index: 2
    display: flex
    position: absolute
    flex-direction: column
    align-items: flex-start

.card-label
    min-height: 38px
    display: flex
    align-items: center
    padding: 5px 15px
    color: #fff
    margin-bottom: 5px
    background: #0E3A88
    text-transform: uppercase

.card-btn
    padding: 16px 55px

.card-left
    width: 438px
    max-width: 100%
    .swiper-wrapper
        align-items: center
    .swiper-slide
        display: flex
        align-items: center
        justify-content: center

.card-left-swiper
    // background: url('../img/bg/pattern_bg.png')
    background: #fff
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1)

.card-nav__prev,
.card-nav__next
    top: 50%
    color: $blue
    position: absolute
    transform: translateY(-50%)

.card-nav__next
    right: -35px

.card-nav__prev
    left: -35px

.card-nav-swiper
    height: 92px

.card-nav-swiper
    margin-left: -5px
    margin-right: -5px
    padding: 5px

.card-nav-swiper .swiper-slide
    cursor: pointer
    background: #fff
    background-repeat: no-repeat
    background-size: auto 100%
    background-position: center
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1)
    &:not(.swiper-slide-visible)
        box-shadow: none

.swiper-slide.swiper-slide-thumb-active
    &::before
        top: 0
        left: 0
        right: 0
        bottom: 0
        content: ''
        position: absolute
        background: $blue
        opacity: .5

.card-tabs
    margin-left: -20px
    margin-right: -20px
    position: relative
    .row
        z-index: 1
        position: relative
        padding-left: 20px
        padding-right: 20px
    &:after
        height: 2px
        left: 20px
        right: 20px
        top: 100%
        content: ''
        position: absolute
        background: #bdbdbd

.card-tabs__link
    height: 47px
    display: flex
    font-weight: 700
    padding-left: 60px
    padding-right: 60px
    align-items: center
    color: #9A9A9A
    background: url('../img/bg/pattern_bg.png')
    &.active
        color: $blue
        box-shadow: 0 2px 0 $blue
    &:hover,
    &:focus-visible
        color: $blue

.tab-pane
    padding-top: 35px
    p
        margin-bottom: 0 !important

.card-table
    width: 100%
    border: 1px solid #BDBDBD
    thead
        tr
            th
                height: 60px
                font-weight: 700
                padding: 10px 40px
                vertical-align: middle
                border: 1px solid #BDBDBD
                background: url('../img/bg/pattern_bg.png')
    tbody
        tr
            td
                height: 40px
                padding: 5px 40px
                border: 1px solid #BDBDBD

.card_news
    box-shadow: none

    .article-date
        margin-bottom: 6px

    .card-body
        padding: 38px 74px

    .card-body__name
        font-size: 24px
        line-height: 1.5
        letter-spacing: .03em

    .card-body__text
        padding-top: 20px
        padding-bottom: 30px

.card_articles
    box-shadow: none

    .card-body
        padding: 44px 60px 50px

    .article-date
        margin-bottom: 27px

.card_articles
    position: relative

.card-inner-link
    top: 0
    left: 0
    right: 0
    bottom: 0
    position: absolute

.card-img
    display: flex
    align-items: center
    justify-content: center
    align-items: center
    height: 347px
    img
        display: block
        max-height: 100% !important
        margin-left: auto
        margin-right: auto

@include media-breakpoint-down(xl)
    .card_articles .card-body,
    .card-body
        padding: 25px 30px 30px

@include media-breakpoint-up(lg)
    .card-left
        .card-labels
            display: none

@include media-breakpoint-down(lg)
    .card-img
        height: 320px
    .card_news .card-body
        padding: 35px 30px
    .card_news .card-body__name
        font-size: 20px
    .card-tabs__link
        height: 40px
        padding-left: 40px
        padding-right: 40px
    .card-table
        font-size: 14px
    .card-table thead tr th
        padding: 10px 20px
        height: 50px
    .card-table tbody tr td
        padding: 5px 20px

@include media-breakpoint-down(md)
    .nav
        overflow-x: auto
        padding-bottom: 2px
    .card_news .card-body__name
        font-size: 18px
    .card-nav__prev
        left: -20px
    .card-nav__next
        right: -20px
    .card-tabs:after
        transform: translateY(-2px)
    .card-btn
        width: 100%
        padding: 10px 30px

    .card-swiper__prev,
    .card-swiper__next
        top: 100px
        z-index: 1
        position: absolute

    .card-swiper__prev
        left: -10px

    .card-swiper__next
        right: -10px

@include media-breakpoint-down(sm)
    .card-img
        height: 280px
    .card_news .card-body
        padding: 30px 20px
    .card_news .card-body__text
        padding-top: 15px
        padding-bottom: 20px
    .card-left
        width: 100%
        margin-bottom: 40px
        .swiper-slide
            img
                max-height: 380px
    .card-nav__prev,
    .card-nav__next
        top: 50%
        z-index: 1
        width: 44px
        height: 44px
        position: absolute
        transform: translateY(-50%)

    .card-nav__prev
        left: -15px

    .card-nav__next
        right: -15px

    .card-body__name
        font-size: 18px
    .card_filter .card-body,
    .card-body
        padding: 20px 20px 30px
    .card-body__text
        padding-top: 15px
        padding-bottom: 25px
