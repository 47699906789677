// Colors ------------------------------------------------------------------------------------

$white: white
$black: #000
$red: red
$green: green
$gray: #F9F9F9
$gray-light: #EFF0F3
$gray-lighter: #BDBDBD
$gray-dark: #848484
$blue: #0E3A88
$brown: brown
$yellow: #FFC700

$color-custom: ( white: #fff, black: $black, red: $red, gray-dark: $gray-dark, yellow: $yellow, gray-light: $gray-light, gray-lighter: $gray-lighter, blue: $blue)

$link-color: $blue

$enable-grid-classes: true !default

$grid-gutter-width: 30px !default

$grid-columns: 12 !default

$spacer-custom: ( 0, 3, 5, 6, 7, 8, 10, 12, 13, 14, 15, 16, 20, 22, 25, 30, 35, 40, 42, 45, 50, 55, 60, 65, 70, 75, 80, 85, 100, 115, 125, 135, 140 )

$width-height: ( 5, 6, 8, 10, 12, 14, 16, 18, 19, 20, 21, 22, 34, 40, 44, 52, 60 )

$width: ( 5, 6, 8, 10, 12, 14, 16, 17, 18, 20, 25, 30, 38, 150, 190 )

$height: ( 5, 6, 8, 10, 12, 14, 16, 18, 20, 22, 25, 34, 44, 60 )

$gutter: ( 0, 5, 6, 10, 14, 15, 16, 18, 20, 22, 24, 25, 28, 30, 40, 60 )

$font-size: ( 10, 12, 13, 14, 15, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36, 40, 44, 48 )

$xxl: 1700px
$xxl-max: 1699.98px

$xl: 1366px
$xl-max: 1365.98px

$lg: 1024px
$lg-max: 1023.98px
$lg-only: (min-width:'1024px') (max-width: '1365.08px')

$md: 768px
$md-max: 767.98px
$md-only: (min-width:'768px') (max-width: '1023.98px')

$sm: 576px
$sm-max: 575.98px

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 1024px, xl: 1366px, xxl: 1700px)
